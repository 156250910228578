import actions from "./actionTypes";

export const setState = items => {
  return {
    type: actions.SET_STATE,
    payload: items
  };
};

export const getDivisions = () => {
  return {
    type: actions.GET_DIVISIONS
  };
};

export const getDivisionsSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { divisionsList: c, loadingDivisions: false }
  };
};

export const getDivisionsFail = err => {
  return {
    type: actions.SET_STATE,
    payload: { error: err.statusText, loadingDivisions: false }
  };
};
