import { all, takeEvery, put, call } from "redux-saga/effects";
import { message } from "antd";
import store from "store";
import {
  // getCurrentAccount,
  getToken,
  postLogout,
  postUser,
  activateUser,
  activationlink,
  getMe,
  changePassword,
  updateProfile
} from "helpers/backend_helpers";

import {
  setState,
  loginSuccess,
  loadCurrentFail,
  loadCurrentSuccess,
  registerSuccess,
  registerFail,
  activateSuccess,
  activateFail,
  activationLinkFail,
  activationLinkSuccess
} from "./actions";
import actions from "./actionTypes";

export function* LOGIN({ payload }) {
  const { email, password } = payload;
  store.remove("accessToken");
  yield put(setState({ loading: true }));
  try {
    const response = yield call(getToken, email, password);
    yield put(loginSuccess(response));
  } catch (error) {
    yield put(loadCurrentFail(error.response));
  }
}

export function* REGISTER({ payload }) {
  yield put(setState({ loading: true }));
  try {
    const response = yield call(postUser, payload);
    yield put(registerSuccess(response));
  } catch (error) {
    console.log(error.response);
    if (typeof error.response.data === "object") {
      Object.keys(error.response.data).forEach(function(key, index) {
        console.log(error.response.data[key]);
      });
    }

    yield put(registerFail(error.response));
  }
}

export function* ACTIVATEUSER({ payload }) {
  yield put(setState({ loading: true }));
  try {
    const response = yield call(activateUser, payload);
    yield put(activateSuccess(response));
  } catch (error) {
    yield put(activateFail(error.response));
  }
}

export function* RESENDACTIVATION({ payload }) {
  yield put(setState({ loading: true }));
  try {
    const response = yield call(activationlink, payload);
    yield put(activationLinkSuccess(response));
  } catch (error) {
    yield put(activationLinkFail(error));
  }
}

export function* SET_PASSWORD({ payload }) {
  yield put(setState({ loading: true }));
  try {
    yield call(changePassword, payload);
    yield put(setState({ loading: false }));
    message.success("Password change successful");
  } catch (error) {
    yield put(setState({ loading: false }));
    message.error("Password change failed");
  }
}

export function* UPDATEPROFILE({ payload }) {
  yield put(setState({ loading: true }));
  try {
    yield call(updateProfile, payload);
    yield put({
      type: "user/LOAD_CURRENT_ACCOUNT"
    });
    message.success("Profile updated successfully");
  } catch (error) {
    yield put(setState({ loading: false }));
    message.error("Profile update failed failed");
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put(setState({ loading: true }));
  try {
    const response = yield call(getMe);
    yield put(loadCurrentSuccess(response));
  } catch (error) {
    yield put(loadCurrentFail());
  }
}

export function* LOGOUT() {
  yield call(postLogout);
  yield put({
    type: "user/SET_STATE",
    payload: {
      id: "",
      name: "",
      role: "",
      email: "",
      avatar: "",
      authorized: false,
      loading: false
    }
  });
  store.remove("accessToken");
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.ACTIVATEUSER, ACTIVATEUSER),
    takeEvery(actions.RESENDACTIVATION, RESENDACTIVATION),
    takeEvery(actions.SET_PASSWORD, SET_PASSWORD),
    takeEvery(actions.UPDATEPROFILE, UPDATEPROFILE),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT()
  ]);
}
