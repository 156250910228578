import { takeEvery, put, call, all } from "redux-saga/effects";
// import { history } from "index";
import { message } from "antd";
import actions from "./actionTypes";
import {
  setState,
  getPlayersFail,
  getPlayersSuccess,
  postPlayersSuccess,
  getPlayerSuccess,
  getPlayerFail,
  postPastclubSuccess,
  postPastclubFail,
  postPlayerFail,
  getPastclubSuccess,
  getPastclubFail,
  deletetPastclubSuccess,
  getPastclubPlayer,
  getPlayer,
  getPlayersCurrentSuccess,
  getPlayersCurrentFail,
  playersUnapprovedSuccess
} from "./actions";

import {
  postPlayers,
  getPlayers,
  getPlayerAPI,
  patchPlayer,
  postPastclub,
  getPastclubPlayerAPI,
  deletePastclubPlayer,
  getPlayersCurrent,
  getPlayersUnapproved,
  getPlayerLinkUnapproved,
  patchPastclub,
  deletePlayerAPI
} from "../../helpers/backend_helpers";

export function* onGetPlayers({ payload }) {
  yield put(setState({ loadingPlayers: true }));
  try {
    const response = yield call(getPlayers, payload);
    yield put(setState({ loadingPlayers: false }));
    yield put(getPlayersSuccess(response));
  } catch (error) {
    yield put(setState({ loadingPlayers: false }));
    yield put(getPlayersFail(error.response));
  }
}

export function* onPostPlayers({ payload }) {
  yield put(setState({ loadingPlayers: true }));
  try {
    const response = yield call(postPlayers, payload);
    yield put(postPlayersSuccess(response));
  } catch (error) {
    yield put(setState({ loadingPlayers: false }));
    yield put(postPlayerFail(error.response));
  }
}

export function* onGetPlayer({ payload }) {
  yield put(setState({ loadingPlayerDetail: true }));
  try {
    const response = yield call(getPlayerAPI, payload);
    yield put(getPlayerSuccess(response));
    yield put(setState({ loadingPlayerDetail: false }));
  } catch (error) {
    yield put(setState({ loadingPlayerDetail: false }));
    yield put(getPlayerFail(error.response));
  }
}

export function* onDeletePlayer({ payload }) {
  yield put(setState({ loadingPlayerDetail: true }));
  try {
    const response = yield call(deletePlayerAPI, payload);
    yield put(getPlayerSuccess(response));
    yield put(setState({ loadingPlayerDetail: false }));
    yield message.success("Player detail removed successfully");
  } catch (error) {
    yield put(setState({ loadingPlayerDetail: false }));
    yield put(getPlayerFail(error.response));
    yield message.error("Player removal failed");
  }
}

export function* onEditPlayer({ payload }) {
  yield put(setState({ loadingPlayers: true }));
  try {
    yield call(patchPlayer, payload);
    yield put(getPlayer(payload.pk));
    yield put({
      type: "players/GET_PLAYERS_UNAPPROVED"
    });
    yield put(setState({ loadingPlayers: false }));
    yield message.success("Player detail updated successfully");
  } catch (error) {
    yield put(setState({ loadingPlayers: false }));
    yield put(getPlayerFail(error.response));
  }
}

export function* onPostPastclub({ payload }) {
  yield put(setState({ loadingPastClub: true }));

  try {
    const response = yield call(postPastclub, payload);
    yield put(postPastclubSuccess(response));
    yield put(getPastclubPlayer(payload.player));
    yield put(setState({ loadingPastClub: false }));
  } catch (error) {
    yield put(setState({ loadingPastClub: false }));
    yield put(postPastclubFail(error.response));
  }
}

export function* onPatchPastclub({ payload }) {
  yield put(setState({ loadingPastClub: true }));
  try {
    const response = yield call(patchPastclub, payload);
    yield put(postPastclubSuccess(response));
    yield put(setState({ loadingPastClub: true }));
  } catch (error) {
    yield put(postPastclubFail(error.response));
    yield put(setState({ loadingPastClub: true }));
  }
}

export function* onGetPastclub({ payload }) {
  yield put(setState({ loadingPastClub: true }));
  try {
    const response = yield call(getPastclubPlayerAPI, payload);
    yield put(getPastclubSuccess(response));
    yield put(setState({ loadingPastClub: false }));
  } catch (error) {
    yield put(setState({ loadingPastClub: false }));
    yield put(getPastclubFail(error.response));
  }
}

export function* onGetPlayersCurrent({ payload }) {
  yield put(setState({ loadingPastClub: true }));
  try {
    const response = yield call(getPlayersCurrent, payload);
    yield put(getPlayersCurrentSuccess(response));
    yield put(setState({ loadingPastClub: false }));
  } catch (error) {
    yield put(setState({ loadingPastClub: false }));
    yield put(getPlayersCurrentFail(error.response));
  }
}

export function* onDeletePastclub({ payload }) {
  yield put(setState({ loadingPastClub: true }));
  try {
    const response = yield call(deletePastclubPlayer, payload.id);
    yield put(deletetPastclubSuccess(response));
    yield put(getPastclubPlayer(payload.player));
    yield put(setState({ loadingPastClub: false }));
  } catch (error) {
    yield put(setState({ loadingPastClub: false }));
    yield put(getPastclubFail(error.response));
  }
}

export function* onGetPlayersUnapproved() {
  yield put(setState({ loadingPlayers: true }));
  try {
    const response = yield call(getPlayersUnapproved);
    yield put(playersUnapprovedSuccess(response));
    yield put(setState({ loadingPlayers: false }));
  } catch (error) {
    yield put(setState({ loadingPlayers: false }));
    yield message.error("Failed to get Unapproved users");
  }
}

export function* onGetPlayersLinkUnapproved() {
  yield put(setState({ loadingPlayers: true }));
  try {
    const response = yield call(getPlayerLinkUnapproved);
    yield put(playersUnapprovedSuccess(response));
    yield put(setState({ loadingPlayers: false }));
  } catch (error) {
    yield put(setState({ loadingPlayers: false }));
    yield message.error("Failed to get Unapproved users");
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CREATE_PLAYERS, onPostPlayers)]);
  yield all([takeEvery(actions.GET_PLAYERS, onGetPlayers)]);
  yield all([takeEvery(actions.GET_PLAYER, onGetPlayer)]);
  yield all([takeEvery(actions.EDIT_PLAYER, onEditPlayer)]);
  yield all([takeEvery(actions.CREATE_PASTCLUB, onPostPastclub)]);
  yield all([takeEvery(actions.GET_PASTCLUB, onGetPastclub)]);
  yield all([takeEvery(actions.DELETE_PASTCLUB, onDeletePastclub)]);
  yield all([takeEvery(actions.PATCH_PASTCLUB, onPatchPastclub)]);
  yield all([takeEvery(actions.GET_PLAYERS_CURRENT, onGetPlayersCurrent)]);
  yield all([takeEvery(actions.DELETE_PLAYER, onDeletePlayer)]);
  yield all([
    takeEvery(actions.GET_PLAYERS_LINK_UNAPPROVED, onGetPlayersLinkUnapproved)
  ]);
  yield all([
    takeEvery(actions.GET_PLAYERS_UNAPPROVED, onGetPlayersUnapproved)
  ]);
}
