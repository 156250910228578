import { notification } from "antd";
import actions from "./actionTypes";

export const setState = items => {
  return {
    type: actions.SET_STATE,
    payload: items
  };
};

export const getSeasons = () => {
  return {
    type: actions.GET_SEASON
  };
};

export const getSeasonSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: {
      seasons: c,
      loadingSeason: false
    }
  };
};

export const getSeasonFail = err => {
  return {
    type: actions.SET_STATE,
    payload: {
      error: err.statusText,
      loadingSeason: false
    }
  };
};

export const postSeason = c => {
  return {
    type: actions.CREATE_SEASON,
    payload: c
  };
};

export const updateSeason = c => {
  return {
    type: actions.EDIT_SEASON,
    payload: c
  };
};

export const patchSeasonSuccess = c => {
  return {
    type: actions.EDIT_SEASON,
    payload: c
  };
};

export const genericFail = err => {
  notification.error({
    message: `Action Failed`
  });
  return {
    type: actions.SET_STATE,
    payload: { error: err.statusText, loading: false }
  };
};
