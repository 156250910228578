import { notification } from "antd";
import actions from "./actionTypes";

export const setState = items => {
  return {
    type: actions.SET_STATE,
    payload: items
  };
};

export const postSponsor = items => {
  return {
    type: actions.CREATE_SPONSOR,
    payload: items
  };
};

export const updateSponsor = items => {
  return {
    type: actions.EDIT_SPONSOR,
    payload: items
  };
};

export const deleteSponsors = id => {
  return {
    type: actions.DELETE_SPONSORS,
    payload: id
  };
};

export const getSponsors = () => {
  return {
    type: actions.GET_SPONSORS
  };
};

export const getSponsorsSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { sponsorsList: c, loadingSponsors: false }
  };
};

export const getSponsorsFail = err => {
  return {
    type: actions.SET_STATE,
    payload: { error: err.statusText, loadingSponsors: false }
  };
};

export const createSponsorSuccess = res => {
  notification.success({
    message: `${res.name} submitted successfully`
  });
  return {
    type: actions.SET_STATE,
    payload: { loading: false }
  };
};

export const createSponsorFail = err => {
  return {
    type: actions.SET_STATE,
    payload: { error: err, loading: false }
  };
};

export const genericSuccess = () => {
  return {
    type: actions.SET_STATE,
    payload: { loadingSponsors: false }
  };
};
export const genericFail = err => {
  return {
    type: actions.SET_STATE,
    payload: { error: err.statusText, loadingSponsors: false }
  };
};
