import { message } from "antd";
import actions from "./actionTypes";

export const setState = items => {
  return {
    type: actions.SET_STATE,
    payload: items
  };
};

export const getUsers = payl => {
  return {
    type: actions.GET_USERS,
    payload: payl
  };
};

export const getUsersUnapproved = () => {
  return {
    type: actions.GET_USERS_UNAPPROVED
  };
};

export const getUnapprovedUsersSuccess = items => {
  return {
    type: actions.SET_STATE,
    payload: {
      unapproved: items
    }
  };
};

export const getStaffSeason = items => {
  return {
    type: actions.GET_STAFF_SEASON,
    payload: items
  };
};

export const getUser = id => {
  return {
    type: actions.GET_USER,
    payload: id
  };
};

export const getUserPresentClub = id => {
  return {
    type: actions.GET_PRESENT_CLUB,
    payload: id
  };
};

export const getStaff = id => {
  return {
    type: actions.GET_STAFF,
    payload: id
  };
};

export const getStaffCurrent = id => {
  return {
    type: actions.GET_STAFF_CURRENT,
    payload: id
  };
};

export const getStaffSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { staff: c, loadingUsers: false }
  };
};

export const getUserSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { userDetail: c, loadingUsers: false }
  };
};

export const getStaffPresentSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { presentClubs: c, loadingPastClubs: false }
  };
};

export const getStaffFail = err => {
  return {
    type: actions.SET_STATE,
    payload: { error: err, loadingUsers: false, staff: [] }
  };
};

export const getUsersSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { users: c, loadingUsers: false }
  };
};

export const getUsersFail = err => {
  return {
    type: actions.SET_STATE,
    payload: { error: err, loadingUsers: false }
  };
};

export const postStaffRoles = data => {
  return {
    type: actions.POST_USER_ROLES,
    payload: data
  };
};

export const postStaffRolesSuccess = () => {
  message.success("Roles added successfully");
  return {
    type: actions.SET_STATE,
    payload: {
      loadingUsers: false
    }
  };
};

export const editUser = data => {
  return {
    type: actions.UPDATE_USER,
    payload: data
  };
};

export const editStaff = (staffdata, idno) => {
  return {
    type: actions.UPDATE_STAFF,
    payload: {
      id: idno,
      data: staffdata
    }
  };
};
