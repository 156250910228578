import React from "react";
import { connect } from "react-redux";
import UserMenu from "./UserMenu";
import style from "./style.module.scss";

const mapStateToProps = ({ settings }) => ({
  isMobileView: settings.isMobileView
});

const TopBar = ({ isMobileView }) => {
  return (
    <div className={style.topbar}>
      {isMobileView && (
        <div className={style.logo} style={{ flex: 1 }}>
          <img
            src={`${process.env.PUBLIC_URL}/resources/images/logo.png`}
            className="mr-2"
            alt="FUBA"
            style={{ maxHeight: "50px" }}
          />
        </div>
      )}
      <div>
        <UserMenu />
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(TopBar);
