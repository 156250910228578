import React from "react";
import { Carousel, Button } from "antd";
import style from "./style.module.scss";

const Slider = props => {
  const { items, multiple, single } = props;
  return (
    <Carousel autoplay effect="fade">
      {items && multiple === false && (
        <div key={items.id}>
          <div className={`${style.carouselContainer}`}>
            <div>
              <img
                src={
                  items.image ||
                  `${process.env.PUBLIC_URL}/resources/images/content/photos/1.jpeg`
                }
                alt="carousel item"
                className={`${style.image}`}
              />
            </div>
            <div className={`${style.overlayBody}`}>
              <div style={{ maxWidth: "80%" }}>
                <p className={`${style.headerText}`}>
                  {items.title && items.title}
                </p>
                <p className={`${style.description}`}>
                  {items.description && items.description}
                </p>
                {items.buttontext && items.urlink && (
                  <div className={style.callAction}>
                    <Button
                      shape="round"
                      size="medium"
                      className="mr-3 mb-3"
                      onClick={() => window.open(items.urlink)}
                    >
                      {items.buttontext}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {items &&
        single === false &&
        items.map(item => {
          return (
            <div key={item.id}>
              <div className={`${style.carouselContainer}`}>
                <div>
                  <img
                    src={
                      item.image ||
                      `${process.env.PUBLIC_URL}/resources/images/content/photos/1.jpeg`
                    }
                    alt="carousel item"
                    className={`${style.image}`}
                  />
                </div>
                <div className={`${style.overlayBody}`}>
                  <div style={{ maxWidth: "80%" }}>
                    <p className={`${style.headerText}`}>
                      {item.title && item.title}
                    </p>
                    <p className={`${style.description}`}>
                      {item.description && item.description}
                    </p>
                    {item.buttontext && item.urlink && (
                      <div className={style.callAction}>
                        <Button
                          shape="round"
                          size="medium"
                          className="mr-3 mb-3"
                          onClick={() => window.open(item.urlink)}
                        >
                          {item.buttontext}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </Carousel>
  );
};

export default Slider;
