import jwtDecode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import { get, patch, post, del } from "./api_helper";
import * as url from "./url_helper";

export const getToken = (mail, pass) =>
  post(url.GET_TOKEN, { email: mail, password: pass });

export const refreshtoken = () => post(url.GET_REFRESH_TOKEN);
export const activateUser = data => post(url.ACTIVATE_USER, data);
export const activationlink = data => post(url.ACTIVATION_LINK, data);

export const getClubs = () => get(url.GET_CLUBS);
export const getClubsShort = () => get(url.GET_CLUBS_SHORT);
export const getClubsGrouped = () => get(url.GET_CLUBS_GROUPED);
export const getClubDetails = pk => get(`${url.GET_CLUBS}${pk}`);
export const patchClub = clubdata =>
  patch(`${url.GET_CLUBS}${clubdata.pk}/`, clubdata.data);
export const getClubsUnapproved = () => get(url.CLUBS_UNAPPROVED);

export const searchId = query => get(`${url.SEARCHID}${query}`);
export const searchSlug = query => get(`${url.SEARCHSLUG}${query}`);

export const getCurrentAccount = pk => get(`${url.GET_ACCOUNT}${pk}`);

// sponsors
export const getSponsors = () => get(url.GET_SPONSORS);
export const postSponsor = data => post(url.GET_SPONSORS, data);
export const deleteSponsor = id => del(`${url.GET_SPONSORS}${id}/`);
export const updateSponsor = payload =>
  patch(`${url.GET_SPONSORS}${payload.id}/`, payload.data);

// Divisions
export const getDivisions = () => get(url.GET_DIVISIONS);
export const getBanks = () => get(url.GET_BANKS);

export const postUser = data => post(url.GET_USERS, data);
export const postLogout = () => post(url.LOGOUT_USER);

export const postClub = data => post(url.GET_CLUBS, data);

export const postPlayers = data => post(url.GET_PLAYERS, data);
export const patchPlayer = playerdata =>
  patch(`${url.GET_PLAYERS}${playerdata.pk}/`, playerdata.data);

export const postPastclub = data => post(url.PAST_CLUB, data);
export const getPastclubPlayerAPI = id => get(`${url.PAST_CLUB_PLAYER}${id}`);
export const deletePastclubPlayer = id => del(`${url.PAST_CLUB}${id}/`);

export const patchPastclub = pastdata =>
  patch(`${url.PAST_CLUB}${pastdata.id}/`, pastdata.data);

export const getPlayers = payload =>
  get(`${url.GET_PLAYERS}?page=${payload.page}&page_size=${payload.page_size}`);

export const getPlayerAPI = id => get(`${url.GET_PLAYERS}${id}`);
export const deletePlayerAPI = id => del(`${url.GET_PLAYERS}${id}`);

export const getPlayersCurrent = clubslug =>
  get(`${url.PLAYERS_CURRENT}${clubslug}`);

export const getPlayersUnapproved = () => get(url.PLAYERS_UNAPPROVED);
export const getPlayerLinkUnapproved = () => get(url.PLAYERS_LINK_UNAPPROVED);

export const getRoles = () => get(url.GET_ROLES);
export const postRoles = data => post(url.GET_ROLES, data);

export const postStaffRoles = data => post(url.STAFF, data);

export const getStaffCurrent = clubslug =>
  get(`${url.STAFF_CURRENT}${clubslug}`);

export const getStaffSeason = payload =>
  get(`${url.STAFF_SEASON}${payload.club_slug}/${payload.year}`);

export const getPermissions = () => get(url.GET_PERMISSIONS);

// Seasons
export const getSeasons = () => get(url.SEASON);
export const postSeason = data => post(url.SEASON, data);
export const updateSeason = item =>
  patch(`${url.SEASON}${item.pk}/`, item.data);

// passwords
export const changePassword = data => post(url.SET_PASSWORD, data);

// profile & User

export const updateProfile = data => patch(url.USER_PROFILE, data);
export const updateUser = payload =>
  patch(`${url.GET_USERS}${payload.pk}/`, payload.data);

export const getUsersList = payload =>
  get(`${url.GET_USERS}?page=${payload.page}&page_size=${payload.page_size}`);
export const getUserUnapproved = () => get(url.USER_UNAPPROVED);
export const getUser = pk => get(`${url.GET_USERS}${pk}`);
export const getMe = () => get(url.USER_PROFILE);
export const updateStaff = payload =>
  patch(`${url.STAFF}${payload.id}/`, payload.data);

export const getStaffPresentClub = userid =>
  get(`${url.STAFF_PRESENT_CLUB}${userid}`);

// Carousel
export const getCarousel = () => get(url.CAROUSEL);
export const patchCarousel = payload =>
  patch(`${url.CAROUSEL}${payload.id}/`, payload.data);
export const deleteCarousel = id => del(`${url.CAROUSEL}${id}/`);
export const postCarousel = payload => post(url.CAROUSEL, payload);

const jwtTimeDiff = decoded => {
  const today = new Date(Date.now().valueOf());
  const tomorrow = new Date(decoded.exp * 1000);
  return (tomorrow - today) / 1000 / 60;
};

export const getValidtoken = token => {
  if (token === undefined) {
    return false;
  }
  const decoded = jwtDecode(token);
  if (!decoded) {
    return false;
  }
  const time = jwtTimeDiff(jwtDecode(token));
  if (time && time > 0) {
    return true;
  }
  return false;
};

export const identification = den => {
  switch (den) {
    case "NATIONAL_ID":
      return "National ID";
    case "DRIVING_PERMIT":
      return "Driving License";
    case "PASSPORT":
      return "Passport";
    default:
      return "None";
  }
};

// extract original file name from image
const getFileName = fileitem => {
  return fileitem.substring(fileitem.lastIndexOf("/") + 1);
};

// Create file object
export const getFileItem = item => {
  if (item) {
    const k = [];
    k.push({
      uid: uuidv4(),
      name: getFileName(item),
      status: "done",
      url: item,
      key: uuidv4()
    });
    return k;
  }
  return null;
};
