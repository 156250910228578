import actions from "./actionTypes";

const initialState = {
  loadingUsers: false,
  loadingPastClubs: false,
  users: [],
  unapproved: [],
  error: "",
  userDetail: [],
  staff: [],
  presentClubs: []
};

export default function staffReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
