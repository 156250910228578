import { takeEvery, put, call, all } from "redux-saga/effects";
import { message } from "antd";
import actions from "./actionTypes";

import { setState, getCarouselSuccess, getCarouselFail } from "./actions";
import {
  getCarousel,
  patchCarousel,
  deleteCarousel,
  postCarousel
} from "../../helpers/backend_helpers";

export function* onGetCarousels() {
  yield put(setState({ loadingCarousel: true }));
  try {
    const response = yield call(getCarousel);
    yield put(getCarouselSuccess(response));
  } catch (error) {
    yield put(getCarouselFail(error));
  }
}

export function* onEditCarousel({ payload }) {
  yield put(setState({ loadingCarousel: true }));
  try {
    yield call(patchCarousel, payload);
    yield put(setState({ success: true }));
    yield message.success("Carousel updated successfully");
    yield put(setState({ loadingCarousel: false, success: false }));
  } catch (error) {
    yield put(setState({ loadingCarousel: false }));
    yield message.error("Carousel update failed");
  }
}

export function* onCreateCarousel({ payload }) {
  yield put(setState({ loadingCarousel: true }));
  try {
    yield call(postCarousel, payload);
    yield put(setState({ success: true }));
    yield message.success("Carousel added successfully");
    yield put(setState({ loadingCarousel: false, success: false }));
  } catch (error) {
    message.error("Failed to add Carousel");
    yield put(setState({ loadingCarousel: false }));
  }
}

export function* onDeleteCarousel({ payload }) {
  yield put(setState({ loadingCarousel: true }));
  try {
    yield call(deleteCarousel, payload);
    yield put(setState({ success: true }));
    yield message.success("Carousel deleted successfully");
    yield put(setState({ loadingCarousel: false, success: false }));
  } catch (error) {
    message.error("Failed to delete Carousel");
    yield put(setState({ loadingCarousel: false }));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CREATE_CAROUSEL, onCreateCarousel)]);
  yield all([takeEvery(actions.PATCH_CAROUSEL, onEditCarousel)]);
  yield all([takeEvery(actions.GET_CAROUSEL, onGetCarousels)]);
  yield all([takeEvery(actions.DELETE_CAROUSEL, onDeleteCarousel)]);
}
