const actions = {
  SET_STATE: "players/SET_STATE",
  GET_PLAYERS: "players/GET_PLAYERS",
  GET_PLAYERS_UNAPPROVED: "players/GET_PLAYERS_UNAPPROVED",
  GET_PLAYERS_LINK_UNAPPROVED: "players/GET_PLAYERS_LINK_UNAPPROVED",
  GET_PLAYER: "players/GET_PLAYER",
  GET_PLAYERS_CURRENT: "players/GET_PLAYERS_CURRENT",
  GET_PASTCLUB: "players/GET_PASTCLUB",
  EDIT_PLAYER: "players/EDIT_PLAYER",
  DELETE_PLAYER: "players/DELETE_PLAYER",

  GET_PLAYERS_SUCCESS: "players/GET_PLAYERS_SUCCESS",

  CREATE_PLAYERS: "players/CREATE_PLAYERS",
  CREATE_PASTCLUB: "players/CREATE_PASTCLUB",
  DELETE_PASTCLUB: "players/DELETE_PASTCLUB",
  PATCH_PASTCLUB: "players/PATCH_PASTCLUB"
};

export default actions;
