import { Button, Spin } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

const CommonError = props => {
  const { err } = props;
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="text-center">
      {err === "" ? (
        <div className="spin-container">
          <Spin indicator={loadingIcon} />
        </div>
      ) : (
        <div>
          <p>Hmm...something went wrong, refresh page to try again!</p>
          <div>
            <Button type="primary">
              <Link to="/">Go Home</Link>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommonError;
