import React, { useState } from "react";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Avatar, Button, Modal } from "antd";
import Login from "components/cleanui/system/Auth/Login";
import styles from "./style.module.scss";

const mapStateToProps = ({ user }) => ({ user });

const ProfileMenu = ({ dispatch, user }) => {
  const [count, setCount] = useState(7);
  const [visible, setVisible] = useState(false);

  const logout = e => {
    e.preventDefault();
    dispatch({
      type: "user/LOGOUT"
    });
  };

  const addCount = () => {
    setCount(count + 1);
  };

  const { avatar } = user;
  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <strong>Hello {user.name || "Anonymous"}</strong>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>Email</strong>
          {user.email || "—"}
          <br />
          <strong>Phone</strong>
          {user.phone || "—"}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link to={`profile/${user.id}/${user.name.split(" ").join("_")}`}>
          <i className="fe fe-user mr-2" />
          Edit profile
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
  };

  const loginFooter = (
    <div className={styles.fuba_modal_footer}>
      <p className={styles.p_login_modal_footer}>
        By submitting this form you agree to FUBA&apos;s
        <a
          className={styles.login_link}
          href="https://www.fuba.basketball/privacy-policy"
          target="_blank"
          data-type="link"
          data-text="Privacy Policy"
          rel="noopener noreferrer"
        >
          {" "}
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          className={styles.login_link}
          href="https://fuba.basketball/termsofuse"
          target="_blank"
          data-type="link"
          data-text="Terms of Use"
          rel="noopener noreferrer"
        >
          {" "}
          Terms of Use.
        </a>
      </p>
    </div>
  );

  return (
    <div>
      {user.authorized ? (
        <Dropdown overlay={menu} trigger={["click"]} onVisibleChange={addCount}>
          <div className={styles.dropdown}>
            {avatar && avatar !== "" ? (
              <Avatar
                className={styles.avatar}
                shape="square"
                size="large"
                src={avatar}
              />
            ) : (
              <Avatar
                className={styles.avatar}
                shape="square"
                size="large"
                icon={<UserOutlined />}
              />
            )}
          </div>
        </Dropdown>
      ) : (
        <div>
          <div className={styles.ghost_wrapper}>
            <Button ghost onClick={showModal}>
              Login
            </Button>
            <Modal
              centered
              visible={visible}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
              footer={loginFooter}
              onCancel={hideModal}
              maskClosable={false}
            >
              <Login />
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ProfileMenu);
