import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import TeamCard from "components/customcomponents/teamcard";
import CommonError from "components/customcomponents/commonerror";
import Slider from "components/customcomponents/carousel";
import { getClubsGrouped } from "../../../redux/club/actions";
import { getCarousel } from "../../../redux/carousel/actions";

// import { data } from "./data.json";

const mapStateToProps = ({ dispatch, club, carousel }) => ({
  dispatch,
  club,
  carousel
});

const DashboardBeta = ({ dispatch, club, carousel }) => {
  const [selectedIndex, SetSelectedIndex] = useState("NBL Men");

  useEffect(() => {
    dispatch(getClubsGrouped());
    dispatch(getCarousel());
  }, [dispatch]);

  const { carouselist } = carousel;
  const { clubgrouped, loadingClubs, error } = club;

  const tagsData = [
    { name: "NBL Women", index: 0 },
    { name: "NBL Men", index: 1 },
    { name: "DIV I Women", index: 3 },
    { name: "DIV I MEN", index: 4 },
    { name: "DIV II Men", index: 5 }
  ];

  const setIndex = seleted => {
    SetSelectedIndex(seleted);
  };

  return (
    <div>
      <Helmet title="FUBA : Home" />
      <div>
        <Slider items={carouselist} single={false} />
      </div>
      <div className="card overflow-hidden" style={{ marginTop: "20px" }}>
        <div
          className="card-header"
          style={{ fontWeight: "700", fontSize: "16px", color: "black" }}
        >
          Divisions
        </div>
        <div className="card-body">
          <div style={{ marginTop: "20px", marginBottom: "15px" }}>
            {tagsData.map(tag => (
              <button
                key={tag.index}
                type="button"
                className={`btn btn-light mr-2 mb-2 ${
                  selectedIndex === tag.index
                    ? "bg-dark text-white"
                    : "text-primary"
                }`}
                onClick={() => setIndex(tag.name)}
              >
                {tag.name}
              </button>
            ))}
          </div>

          <div className="row">
            {!loadingClubs ? (
              clubgrouped[selectedIndex] &&
              clubgrouped[selectedIndex].map(k => {
                const subchild = (
                  <Link to={`club/${k.slug}`}>
                    <TeamCard image={k.logo} name={k.name} />
                  </Link>
                );
                return (
                  <div className="col-lg-2 col-sm-3 col-xs-12" key={k.id}>
                    {subchild}
                  </div>
                );
              })
            ) : (
              <CommonError err={error} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(DashboardBeta);
