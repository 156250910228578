import React, { useState } from "react";
import { connect } from "react-redux";
import { Input, Button, Form } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import style from "../style.module.scss";

const mapStateToProps = ({ user, settings, dispatch }) => ({
  dispatch,
  user,
  authProvider: settings.authProvider
});

const Login = ({ dispatch, user }) => {
  const [showlogin, setShowlogin] = useState(true);
  // const [showreset, setShowreset] = useState(false);

  const onFinish = values => {
    dispatch({
      type: "user/LOGIN",
      payload: values
    });
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  const resetpassword = () => {
    setShowlogin(false);
  };

  const signin = () => {
    setShowlogin(true);
  };

  return (
    <div>
      {/*       <div className="text-center">
        <div className={` mb-5 ${style.logocontainer}`}>
          <img src="resources/images/logo.png" alt="Pay Hub" className={style.logo} />
        </div>
      </div> */}
      <div className={`card ${style.container} mb-5`}>
        <div className="text-dark  text-center  font-size-24 mb-3">
          {showlogin ? (
            <strong>Sign In</strong>
          ) : (
            <strong>Reset Password</strong>
          )}
        </div>
        {showlogin ? (
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="mb-4"
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Please input your e-mail address" }
              ]}
            >
              <Input size="large" placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your password" }
              ]}
            >
              <Input.Password
                size="large"
                placeholder="Password"
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <div style={{ textAlign: "Left", paddingBottom: "5px" }}>
              <Button
                style={{ fontColor: "black", fontSize: "14px", border: "none" }}
                onClick={resetpassword}
              >
                Forgot Password?
              </Button>
            </div>

            <Button
              type="primary"
              size="large"
              className="text-center w-100"
              htmlType="submit"
              loading={user.loading}
            >
              <strong>Sign in</strong>
            </Button>
          </Form>
        ) : (
          <div>
            <Form
              layout="vertical"
              hideRequiredMark
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="mb-4"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your e-mail address"
                  }
                ]}
              >
                <Input size="large" placeholder="Email Address" />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="text-center w-100"
              >
                <strong>Reset my password</strong>
              </Button>
            </Form>
            <Button
              className="kit__utils__link font-size-16"
              onClick={signin}
              style={{ border: "none" }}
            >
              <i className="fe fe-arrow-left mr-1 align-middle" />
              Go to Sign in
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Login);
