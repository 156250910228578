import { notification } from "antd";
import React from "react";
import actions from "./actionTypes";

export const setState = items => {
  return {
    type: actions.SET_STATE,
    payload: items
  };
};

export const getClubsUnapproved = () => {
  return {
    type: actions.GET_CLUBS_UNAPPROVED
  };
};

export const clubsUnapprovedSuccess = items => {
  return {
    type: actions.SET_STATE,
    payload: {
      unapproved: items,
      loadingClubs: false
    }
  };
};

export const getClubs = () => {
  return {
    type: actions.GET_CLUBS
  };
};

export const getClubsShort = () => {
  return {
    type: actions.GET_CLUBS_SHORT
  };
};
export const getClubsGrouped = () => {
  return {
    type: actions.GET_CLUBS_GROUPED
  };
};

export const getClubsSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { clubs: c, loadingClubs: false }
  };
};

export const getClubsShortSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { clubshort: c, loadingClubs: false }
  };
};

export const getClubsGroupedSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { clubgrouped: c, loadingClubs: false }
  };
};

export const getClubsFail = err => {
  return {
    type: actions.GET_CLUBS_FAIL,
    payload: {
      error: err.statusText,
      loadingClubDetails: false
    }
  };
};

export const getClubDetailSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { clubDetails: c, loadingClubDetail: false }
  };
};

export const postClubSuccess = c => {
  notification.success({
    message: "Success",
    description: "Awaiting approval"
  });
  return {
    type: actions.SET_STATE,
    payload: { clubDetails: c, loadingClubDetail: false }
  };
};

export const getClubDetails = slug => {
  return {
    type: actions.GET_CLUB_DETAILS,
    payload: slug
  };
};

export const postClub = data => {
  return {
    type: actions.CREATE_CLUB,
    payload: data
  };
};

export const postClubFail = err => {
  const cf = {
    "bank": "Bank",
    "name": "Name",
    "slug": "Slug",
    "clubId": "Registration ID",
    "branch": "Branch",
    "accountNumber": "Account Number",
    "homeColor": "Home Color",
    "awayColor": "Away Color",
    "city": "City",
    "home_court": "Home Court",
    "telephone": "Telephone",
    "telephone_two": "Telephone 2",
    "email": "Email",
    "twitter": "Twitter",
    "facebook": "Facebook",
    "instagram": "Instagram",
    "youtube": "Youtube",
    "established": "Established",
    "ownership_type": "Type of Ownership",
    "sponsors": "Sponsors",
    "division": "Division",
    "zone": "Zone"
  };

  const getNot = x => {
    const q = [];
    Object.keys(x).map(k => q.push(`${cf[k]}-${x[k]}`));
    return q;
  };

  if (err.data instanceof Object) {
    notification.warn({
      message: "Failed to create club",
      description: getNot(err.data).map(k => <p>{k}</p>)
    });
  } else {
    notification.warn({
      message: "Failed to create club",
      description: err.statusText
    });
  }

  return {
    type: actions.SET_STATE,
    payload: {
      error: err.statusText,
      loadingClubDetails: false
    }
  };
};

export const editClub = data => {
  return {
    type: actions.EDIT_CLUB,
    payload: data
  };
};
