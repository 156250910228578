import actions from "./actionTypes";

export const setState = items => {
  return {
    type: actions.SET_STATE,
    payload: items
  };
};

export const getCarousel = () => {
  return {
    type: actions.GET_CAROUSEL
  };
};

export const postCarousel = items => {
  return {
    type: actions.CREATE_CAROUSEL,
    payload: items
  };
};

export const deleteCarousel = data => {
  return {
    type: actions.DELETE_CAROUSEL,
    payload: data
  };
};

export const patchCarousel = data => {
  return {
    type: actions.PATCH_CAROUSEL,
    payload: data
  };
};

export const getCarouselSuccess = res => {
  return {
    type: actions.SET_STATE,
    payload: {
      carouselist: res,
      loadingCarousel: false
    }
  };
};

export const getCarouselFail = err => {
  return {
    type: actions.SET_STATE,
    payload: {
      loadingCarousel: false,
      error: err
    }
  };
};
