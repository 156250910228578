import actions from "./actionTypes";

export const setState = items => {
  return {
    type: actions.SET_STATE,
    payload: items
  };
};

export const postRoles = data => {
  return {
    type: actions.POST_ROLES,
    payload: data
  };
};

export const getRoles = () => {
  return {
    type: actions.GET_ROLES
  };
};

export const postRolesSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: {
      playerDetail: c,
      loadingRoles: false
    }
  };
};

export const getRolesFail = err => {
  return {
    type: actions.GET_ROLES_FAIL,
    payload: {
      error: err.statusText,
      loadingRoles: false
    }
  };
};

export const getRolesSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { roles: c, loadingRoles: false }
  };
};

export const getPermissions = () => {
  return {
    type: actions.GET_PERMISSIONS
  };
};

export const getPermissionsSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { permissions: c, loadingRoles: false }
  };
};
