import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import user from "./user/reducers";
import menu from "./menu/reducers";
import settings from "./settings/reducers";
import club from "./club/reducers";
import staff from "./staff/reducers";
import sponsors from "./sponsors/reducers";
import divisions from "./divisions/reducers";
import bank from "./bank/reducers";
import player from "./player/reducers";
import role from "./role/reducers";
import season from "./season/reducers";
import carousel from "./carousel/reducers";

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    club,
    staff,
    sponsors,
    divisions,
    bank,
    player,
    role,
    season,
    carousel
  });
