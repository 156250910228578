import { takeEvery, put, call, all } from "redux-saga/effects";
import { message } from "antd";
import actions from "./actionTypes";

import {
  getClubsSuccess,
  setState,
  getClubsFail,
  getClubsShortSuccess,
  getClubDetailSuccess,
  getClubsGroupedSuccess,
  postClubSuccess,
  postClubFail,
  clubsUnapprovedSuccess
} from "./actions";

import {
  getClubs,
  getClubsShort,
  getClubDetails,
  getClubsGrouped,
  postClub,
  patchClub,
  getClubsUnapproved
} from "../../helpers/backend_helpers";

export function* onGetClubs() {
  yield put(setState({ loadingClubs: true }));
  try {
    const response = yield call(getClubs);
    yield put(getClubsSuccess(response));
  } catch (error) {
    yield put(setState({ loadingClubs: false }));
    yield put(getClubsFail(error.response));
  }
}

export function* onGetClubsShort() {
  yield put(setState({ loadingClubs: true }));
  try {
    const response = yield call(getClubsShort);
    yield put(getClubsShortSuccess(response));
  } catch (error) {
    yield put(setState({ loadingClubs: false }));
    yield put(getClubsFail(error.response));
  }
}

export function* onGetClubsGrouped() {
  yield put(setState({ loadingClubs: true }));
  try {
    const response = yield call(getClubsGrouped);
    yield put(getClubsGroupedSuccess(response));
  } catch (error) {
    yield put(setState({ loadingClubs: false }));
    yield put(getClubsFail(error.response));
  }
}

export function* onGetClubDetails({ payload: slug }) {
  yield put(setState({ loadingClubDetail: true }));
  try {
    const response = yield call(getClubDetails, slug);
    yield put(getClubDetailSuccess(response));
  } catch (error) {
    yield put(setState({ loadingClubDetail: true }));
  }
}

export function* onPostClub({ payload }) {
  yield put(setState({ loadingClubDetail: true }));
  try {
    const response = yield call(postClub, payload);
    yield put(postClubSuccess(response));
  } catch (error) {
    yield put(setState({ loadingClubDetail: true }));
    yield put(postClubFail(error.response));
  }
}

export function* onEditClub({ payload }) {
  yield put(setState({ loadingClubDetail: true }));
  try {
    yield call(patchClub, payload);
    yield put({
      type: "club/GET_CLUB_DETAILS",
      payload: payload.pk
    });
    yield put(setState({ loadingClubDetail: false }));

    yield message.success("Club detail updated successfully");
  } catch (error) {
    yield put(setState({ loadingClubDetail: false }));
    yield message.error("Club detail update failed");
  }
}

export function* onGetClubssUnapproved() {
  yield put(setState({ loadingClubs: true }));
  try {
    const response = yield call(getClubsUnapproved);
    yield put(clubsUnapprovedSuccess(response));
  } catch (error) {
    yield put(setState({ loadingClubs: false }));
    yield message.error("Failed to get Unapproved Clubs");
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_CLUBS, onGetClubs)]);
  yield all([takeEvery(actions.GET_CLUBS_SHORT, onGetClubsShort)]);
  yield all([takeEvery(actions.GET_CLUB_DETAILS, onGetClubDetails)]);
  yield all([takeEvery(actions.GET_CLUBS_GROUPED, onGetClubsGrouped)]);
  yield all([takeEvery(actions.CREATE_CLUB, onPostClub)]);
  yield all([takeEvery(actions.EDIT_CLUB, onEditClub)]);
  yield all([takeEvery(actions.GET_CLUBS_UNAPPROVED, onGetClubssUnapproved)]);
}
