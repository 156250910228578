const actions = {
  SET_STATE: "role/SET_STATE",
  GET_ROLES: "role/GET_ROLES",

  GET_PERMISSIONS: "role/GET_PERMISSIONS",
  POST_ROLES: "role/POST_ROLES",
  GET_ROLES_SHORT: "role/GET_ROLES_SHORT",
  GET_ROLES_SUCCESS: "role/GET_ROLES_SUCCESS",
  GET_ROLE_DETAIL_SUCCESS: "role/GET_ROLE_DETAIL_SUCCESS",
  GET_ROLES_FAIL: "role/GET_ROLES_FAIL",
  GET_ROLE_DETAILS: "role/GET_ROLE_DETAILS",

  GET_ROLES_GROUPED: "role/GET_ROLES_GROUPED",

  CREATE_ROLE: "role/CREATE_ROLE"
};

export default actions;
