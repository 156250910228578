import React from "react";
import style from "./style.module.scss";

const TeamCard = ({ image, name }) => {
  return (
    <div className="card overflow-hidden">
      <div className="card-body">
        <div className={`${style.image} border-bottom height-150 mb-3`}>
          <img
            className="img-fluid"
            src={
              !image
                ? `${process.env.PUBLIC_URL}/resources/images/avatars/placeholder.png`
                : image
            }
            alt={name}
          />
        </div>
        <div>
          <p className="text-blue font-size-16">{name}</p>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
