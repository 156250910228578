import actions from "./actionTypes";

const initialState = {
  loadingPlayers: false,
  loadingPlayerDetail: false,
  loadingPastClub: false,
  unapproved: [],
  loading: false,
  pastClubs: [],
  players: [],
  playerDetail: [],
  error: "",
  pastClubError: "",
  currentClub: []
};

export default function playerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
