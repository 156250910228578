const actions = {
  SET_STATE: "staff/SET_STATE",
  UPDATE_STAFF: "staff/UPDATE_STAFF",
  GET_STAFF_CURRENT: "staff/GET_STAFF_CURRENT",
  GET_STAFF_SEASON: "staff/GET_STAFF_SEASON",
  GET_PRESENT_CLUB: "staff/GET_PRESENT_CLUB",

  GET_USERS: "staff/GET_USERS",
  GET_USERS_UNAPPROVED: "staff/GET_USERS_UNAPPROVED",

  GET_USERS_FAIL: "staff/GET_USERS_FAIL",
  GET_USER: "staff/GET_USER",
  GET_STAFF: "staff/GET_STAFF",
  POST_USER_ROLES: "staff/POST_USER_ROLES",
  UPDATE_USER: "staff/UPDATE_USER"
};

export default actions;
