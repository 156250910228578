import { takeEvery, put, call, all } from "redux-saga/effects";
import { message } from "antd";
import actions from "./actionTypes";
import {
  getSponsorsFail,
  setState,
  getSponsorsSuccess,
  createSponsorSuccess,
  createSponsorFail,
  genericSuccess,
  genericFail
} from "./actions";

import {
  deleteSponsor,
  getSponsors,
  postSponsor,
  updateSponsor
} from "../../helpers/backend_helpers";

export function* onGetSponsors() {
  yield put(setState({ loadingSponsors: true }));
  try {
    const response = yield call(getSponsors);
    yield put(getSponsorsSuccess(response));
  } catch (error) {
    yield put(getSponsorsFail(error.response));
  }
}

export function* onDeleteSponsors({ payload }) {
  yield put(setState({ loadingSponsors: true }));
  try {
    const response = yield call(deleteSponsor, payload);
    yield put(genericSuccess(response));
    yield put({
      type: "sponsors/GET_SPONSORS"
    });
  } catch (error) {
    yield put(genericFail(error.response));
  }
}

export function* onCreateSponsor({ payload }) {
  yield put(setState({ loading: true }));
  try {
    const response = yield call(postSponsor, payload);
    yield put({
      type: "sponsors/GET_SPONSORS"
    });
    yield put(createSponsorSuccess(response));
  } catch (error) {
    yield put(createSponsorFail(error.response));
  }
}

export function* onEditSponsor({ payload }) {
  yield put(setState({ loading: true }));
  try {
    const response = yield call(updateSponsor, payload);
    yield put({
      type: "sponsors/GET_SPONSORS"
    });
    message.success(`Sponsor ${response.name} updated successfully`);
    yield put(setState({ loading: false }));
  } catch (error) {
    message.error("Update failed");
    yield put(createSponsorFail(error.response));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_SPONSORS, onGetSponsors)]);
  yield all([takeEvery(actions.CREATE_SPONSOR, onCreateSponsor)]);
  yield all([takeEvery(actions.DELETE_SPONSORS, onDeleteSponsors)]);
  yield all([takeEvery(actions.EDIT_SPONSOR, onEditSponsor)]);
}
