import actions from "./actionTypes";

export const setState = items => {
  return {
    type: actions.SET_STATE,
    payload: items
  };
};

export const getBanks = () => {
  return {
    type: actions.GET_BANKS
  };
};

export const getBanksSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: {
      banks: c,
      loadingBanks: false
    }
  };
};

export const getBanksFail = err => {
  return {
    type: actions.SET_STATE,
    payload: {
      error: err.statusText,
      loadingBanks: false
    }
  };
};
