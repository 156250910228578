const actions = {
  SET_STATE: "club/SET_STATE",
  GET_CLUBS: "club/GET_CLUBS",
  EDIT_CLUB: "club/EDIT_CLUB",
  GET_CLUBS_UNAPPROVED: "club/GET_CLUBS_UNAPPROVED",
  GET_CLUBS_SHORT: "club/GET_CLUBS_SHORT",
  GET_CLUBS_SUCCESS: "club/GET_CLUBS_SUCCESS",
  GET_CLUB_DETAIL_SUCCESS: "club/GET_CLUB_DETAIL_SUCCESS",
  GET_CLUBS_FAIL: "club/GET_CLUBS_FAIL",
  GET_CLUB_DETAILS: "club/GET_CLUB_DETAILS",

  GET_CLUBS_GROUPED: "club/GET_CLUBS_GROUPED",

  CREATE_CLUB: "club/CREATE_CLUB"
};

export default actions;
