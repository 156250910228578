import { takeEvery, put, call, all } from "redux-saga/effects";

import { message } from "antd";
import actions from "./actionTypes";
import {
  getUsersSuccess,
  getUsersFail,
  setState,
  getUserSuccess,
  postStaffRolesSuccess,
  getStaffSuccess,
  getStaffFail,
  getUnapprovedUsersSuccess,
  getStaffPresentSuccess
} from "./actions";

import {
  getUsersList,
  getUser,
  postStaffRoles,
  getStaffCurrent,
  getStaffSeason,
  updateUser,
  getUserUnapproved,
  getStaffPresentClub,
  updateStaff
} from "../../helpers/backend_helpers";

export function* onGetUsers({ payload }) {
  yield put(setState({ loadingUsers: true }));
  try {
    const response = yield call(getUsersList, payload);
    yield put(getUsersSuccess(response));
  } catch (error) {
    yield put(getUsersFail(error.response));
  }
}

export function* onGetUser({ payload }) {
  yield put(setState({ loadingUsers: true }));
  try {
    const response = yield call(getUser, payload);
    yield put(getUserSuccess(response));
  } catch (error) {
    yield put(getUsersFail(error.response));
  }
}

export function* onPostStaffRoles({ payload }) {
  yield put(setState({ loadingUsers: true }));
  try {
    const response = yield call(postStaffRoles, payload);
    yield put(postStaffRolesSuccess(response));
  } catch (error) {
    message.error("Addition of role failed");
    yield put(getUsersFail(error.response));
  }
}

export function* oneGetStaff({ payload }) {
  yield put(setState({ loadingUsers: true }));
  try {
    const response = yield call(getStaffCurrent, payload);
    yield put(getStaffSuccess(response));
  } catch (error) {
    yield put(getUsersFail(error.response));
  }
}

export function* oneGetStaffPresentClub({ payload }) {
  yield put(setState({ loadingPastClubs: true }));
  try {
    const response = yield call(getStaffPresentClub, payload);
    yield put(getStaffPresentSuccess(response));
  } catch (error) {
    yield put(setState({ loadingPastClubs: false }));
  }
}

export function* oneGetStaffSeason({ payload }) {
  yield put(setState({ loadingUsers: true }));
  try {
    const response = yield call(getStaffSeason, payload);
    yield put(getStaffSuccess(response));
  } catch (error) {
    yield put(getUsersFail(error.response));
  }
}

export function* onGetStaffCurrent({ payload }) {
  yield put(setState({ loadingUsers: true }));
  try {
    const response = yield call(getStaffCurrent, payload);
    yield put(getStaffSuccess(response));
  } catch (error) {
    yield put(getStaffFail(error.response));
  }
}

export function* onUpdateStaff({ payload }) {
  yield put(setState({ loadingPastClubs: true }));
  try {
    yield call(updateStaff, payload);
    yield put({
      type: "staff/GET_PRESENT_CLUB",
      payload: payload.data.staff
    });
    yield message.success("Role updated successfully");
  } catch (error) {
    yield put(setState({ loadingPastClubs: false }));
    yield message.error("Role update failed");
  }
}

export function* onEditUser({ payload }) {
  yield put(setState({ loadingUsers: true }));
  try {
    yield call(updateUser, payload);
    yield put({
      type: "staff/GET_USER",
      payload: payload.pk
    });
    yield put({
      type: "staff/GET_USERS_UNAPPROVED"
    });
    yield put(setState({ loadingUsers: false }));
    yield put(setState({ loading: false }));
    yield message.success("User detail updated successfully");
  } catch (error) {
    yield put(setState({ loadingUsers: false }));
    yield message.error("User detail update failed");
  }
}

export function* onGetUsersUnapproved() {
  yield put(setState({ loadingUsers: true }));
  try {
    const response = yield call(getUserUnapproved);
    yield put(getUnapprovedUsersSuccess(response));
    yield put(setState({ loadingUsers: false }));
  } catch (error) {
    yield put(setState({ loadingUsers: false }));
    yield message.error("Failed to get Unapproved users");
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_USERS, onGetUsers)]);
  yield all([takeEvery(actions.GET_USER, onGetUser)]);
  yield all([takeEvery(actions.POST_USER_ROLES, onPostStaffRoles)]);
  yield all([takeEvery(actions.GET_STAFF, oneGetStaff)]);
  yield all([takeEvery(actions.GET_STAFF_SEASON, oneGetStaffSeason)]);
  yield all([takeEvery(actions.GET_STAFF_CURRENT, onGetStaffCurrent)]);
  yield all([takeEvery(actions.UPDATE_USER, onEditUser)]);
  yield all([takeEvery(actions.GET_USERS_UNAPPROVED, onGetUsersUnapproved)]);
  yield all([takeEvery(actions.GET_PRESENT_CLUB, oneGetStaffPresentClub)]);
  yield all([takeEvery(actions.UPDATE_STAFF, onUpdateStaff)]);
}
