import { takeEvery, put, call, all } from "redux-saga/effects";
import { notification } from "antd";
import actions from "./actionTypes";
import {
  getSeasonSuccess,
  getSeasonFail,
  setState,
  genericFail
} from "./actions";

import {
  getSeasons,
  postSeason,
  updateSeason
} from "../../helpers/backend_helpers";

export function* onGetSeasons() {
  yield put(setState({ loadingSeason: true }));
  try {
    const response = yield call(getSeasons);
    yield put(getSeasonSuccess(response));
  } catch (error) {
    yield put(getSeasonFail(error.response));
  }
}

export function* onCreateSeason({ payload }) {
  yield put(setState({ loading: true }));
  try {
    const response = yield call(postSeason, payload);
    yield put({
      type: "season/GET_SEASON"
    });
    notification.success({
      message: `Season ${response.name} submitted successfully`
    });
  } catch (error) {
    yield put(genericFail(error.response));
  }
}

export function* onEditSeason({ payload }) {
  yield put(setState({ loading: true }));
  try {
    const response = yield call(updateSeason, payload);
    yield put({
      type: "season/GET_SEASON"
    });
    notification.success({
      message: `Season ${response.name} updated successfully`
    });
  } catch (error) {
    yield put(genericFail(error.response));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_SEASON, onGetSeasons)]);
  yield all([takeEvery(actions.CREATE_SEASON, onCreateSeason)]);
  yield all([takeEvery(actions.EDIT_SEASON, onEditSeason)]);
}
