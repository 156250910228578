import { takeEvery, put, call, all } from "redux-saga/effects";
import actions from "./actionTypes";
import {
  setState,
  getRolesFail,
  getRolesSuccess,
  getPermissionsSuccess
} from "./actions";

import {
  getRoles,
  postRoles,
  getPermissions
} from "../../helpers/backend_helpers";

export function* onGetRoles() {
  yield put(setState({ loadingRoles: true }));
  try {
    const response = yield call(getRoles);
    yield put(getRolesSuccess(response));
  } catch (error) {
    yield put(getRolesFail(error.response));
  }
}

export function* onGetPermissions() {
  yield put(setState({ loadingRoles: true }));
  try {
    const response = yield call(getPermissions);
    yield put(getPermissionsSuccess(response));
  } catch (error) {
    yield put(getRolesFail(error.response));
  }
}

export function* onPostRoles({ payload }) {
  yield put(setState({ loadingRoles: true }));
  try {
    const response = yield call(postRoles, payload);
    yield put(getRolesSuccess(response));
  } catch (error) {
    yield put(getRolesFail(error.response));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_ROLES, onGetRoles)]);
  yield all([takeEvery(actions.POST_ROLES, onPostRoles)]);
  yield all([takeEvery(actions.GET_PERMISSIONS, onGetPermissions)]);
}
