export default async function getMenuData() {
  return [
    {
      title: "Home",
      key: "dashboards",
      // icon: 'fe fe-home',
      url: "/"
    },
    {
      title: "Teams",
      key: "apps",
      roles: ["is_admin", "is_staff", "is_club_admin"],
      // icon: 'fe fe-bookmark',
      children: [
        {
          title: "Add Club",
          key: "clubadd",
          url: "/new/club/create",
          roles: ["is_admin", "is_staff", "is_club_admin"]
        },
        {
          title: "Manage Club",
          key: "appsClub4",
          url: "/clubs/manage",
          roles: ["is_admin", "is_staff", "is_club_admin"]
        },
        {
          title: "Approvals",
          key: "appsClub12",
          url: "/clubs/approvals",
          roles: ["is_admin", "is_staff"]
        }
      ]
    },
    {
      title: "Players",
      key: "apps2",
      roles: ["is_admin", "is_staff", "is_club_admin"],
      // icon: 'fe fe-user',
      children: [
        {
          title: "Add Player",
          key: "appsProfile1",
          url: "/player/create",
          roles: ["is_admin", "is_staff", "is_club_admin"]
        },
        {
          title: "Manage Player",
          key: "appsProfile2",
          url: "/players/manage",
          roles: ["is_admin", "is_staff", "is_club_admin"]
        },
        {
          title: "Approvals",
          key: "appsProfile3",
          url: "/players/approvals",
          roles: ["is_admin", "is_staff"]
        },
        {
          title: "Approve Links",
          key: "appsProfile4",
          url: "/players/link/approvals",
          roles: ["is_admin", "is_staff"]
        }
      ]
    },
    {
      title: "Settings",
      key: "settings1",
      roles: ["is_admin", "is_staff"],
      // icon: 'fe fe-user',
      children: [
        {
          title: "Manage Seasons",
          key: "appsSeasons1",
          url: "/seasons",
          roles: ["is_admin", "is_staff"]
        },
        {
          title: "Manage Divisions",
          key: "appsDivision1",
          url: "/divisions",
          roles: ["is_admin", "is_staff"]
        },
        {
          title: "Manage Sponsors",
          key: "appsSponsors2",
          url: "/sponsors",
          roles: ["is_admin", "is_staff"]
        },
        {
          title: "Manage Carousels",
          key: "appsCarousel",
          url: "/carousel/manage",
          roles: ["is_admin", "is_staff"]
        },
        {
          title: "Users",
          key: "appsSponsors3",
          roles: ["is_admin", "is_staff"],
          children: [
            {
              title: "Manage Users",
              key: "users1",
              url: "/users/manage",
              roles: ["is_admin", "is_staff"]
            },
            {
              title: "Add User",
              key: "users2",
              url: "/user/add",
              roles: ["is_admin", "is_staff", "is_club_admin"]
            },
            {
              title: "Approvals",
              key: "users3",
              url: "/users/approval",
              roles: ["is_admin", "is_staff", "is_club_admin"]
            }
          ]
        },
        {
          title: "Roles",
          key: "appsRoles3",
          children: [
            {
              title: "Add Roles",
              key: "roel2",
              url: "/roles/add",
              roles: ["is_admin", "is_staff", "is_club_admin"]
            }
          ]
        }
      ]
    }
  ];
}
