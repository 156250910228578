import { takeEvery, put, call, all } from "redux-saga/effects";
import actions from "./actionTypes";
import { setState, getDivisionsFail, getDivisionsSuccess } from "./actions";

import { getDivisions } from "../../helpers/backend_helpers";

export function* onGetDivisions() {
  yield put(setState({ loadingDivisions: true }));
  try {
    const response = yield call(getDivisions);
    yield put(getDivisionsSuccess(response));
  } catch (error) {
    yield put(getDivisionsFail(error.response));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_DIVISIONS, onGetDivisions)]);
}
