import actions from "./actionTypes";

const initialState = {
  seasons: [],
  loadingSeason: false,
  error: ""
};

export default function seasonReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
