import { takeEvery, put, call, all } from "redux-saga/effects";
import actions from "./actionTypes";
import { setState, getBanksFail, getBanksSuccess } from "./actions";

import { getBanks } from "../../helpers/backend_helpers";

export function* onGetBanks() {
  yield put(setState({ loadingBanks: true }));
  try {
    const response = yield call(getBanks);
    yield put(getBanksSuccess(response));
  } catch (error) {
    yield put(getBanksFail(error.response));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_BANKS, onGetBanks)]);
}
