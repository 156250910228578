const actions = {
  SET_STATE: "bank/SET_STATE",
  GET_BANKS: "bank/GET_CLUBS",

  GET_BANK_SUCCESS: "bank/GET_BANK_SUCCESS",

  GET_BANK_FAIL: "bank/GET_BANK_FAIL",
  CREATE_BANK: "bank/CREATE_BANK"
};

export default actions;
