import React from "react";
import style from "./style.module.scss";

const Footer = () => {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <a
          href="https://fuba.basketball"
          target="_blank"
          rel="noopener noreferrer"
          className={style.logo}
        >
          FUBA
          <span />
        </a>
        <br />
        <p className="mb-0">
          Copyright © {new Date().getFullYear()} Fuba |{" "}
          <a
            href="https://fuba.basketball"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
