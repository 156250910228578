export const GET_TOKEN = "/auth/token";
export const GET_REFRESH_TOKEN = "/auth/token/refresh";

export const GET_CLUBS = "/club/";
export const GET_CLUBS_SHORT = "/club/list";
export const GET_CLUBS_GROUPED = "/club/grouped";
export const SEARCHID = "/club/confirm";
export const SEARCHSLUG = "club/confirm/slug";

export const GET_SPONSORS = "/sponsor/";
export const GET_DIVISIONS = "/division/";
export const GET_BANKS = "/bank/";

export const GET_PLAYERS = "/players/";
export const PLAYERS_CURRENT = "/players/current/";

export const GET_ROLES = "/role/";
export const GET_PERMISSIONS = "/permission/list";

export const STAFF = "/staff/";
export const STAFF_CURRENT = "/staff/current/";
export const STAFF_SEASON = "/staff/past/";

export const SEASON = "/season/";

export const PAST_CLUB = "/pastclub/";
export const PAST_CLUB_PLAYER = "/pastclub/player/";

export const LOGOUT_USER = "/auth/token/logout";
export const ACTIVATE_USER = "/users/activation/";
export const ACTIVATION_LINK = "/users/resend_activation/";
export const SET_PASSWORD = "/users/set_password/";

// user
export const GET_ACCOUNT = "/userprofile/";
export const GET_USERS = "/users/";
export const UPDATE_PROFILE = "/userprofile/";
export const USER_PROFILE = "/users/me/";
export const USER_UNAPPROVED = "/users/unapproved";
export const PLAYERS_UNAPPROVED = "/players/unapproved";
export const PLAYERS_LINK_UNAPPROVED = "/pastclub/unapproved";
export const CLUBS_UNAPPROVED = "/club/unapproved";
export const STAFF_PRESENT_CLUB = "/staff/present/club/";

// carousel
export const CAROUSEL = "/carousel/";
