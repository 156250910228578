import actions from "./actionTypes";

const initialState = {
  loadingClubs: false,
  loadingClubDetail: false,
  unapproved: [],
  clubs: [],
  clubshort: [],
  clubgrouped: [],
  clubDetails: [],
  error: ""
};

export default function clubReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
