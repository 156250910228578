const actions = {
  SET_STATE: "user/SET_STATE",
  SET_PASSWORD: "user/SET_PASSWORD",
  LOGIN: "user/LOGIN",
  ACTIVATEUSER: "user/ACTIVATEUSER",
  UPDATEPROFILE: "user/UPDATEPROFILE",
  REGISTER: "user/REGISTER",
  LOAD_CURRENT_ACCOUNT: "user/LOAD_CURRENT_ACCOUNT",
  LOGOUT: "user/LOGOUT",
  RESENDACTIVATION: "user/RESENDACTIVATION",
  CREATE: "user/CREATE"
};

export default actions;
