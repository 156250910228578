// src/helpers/api_helper.js
import axios from "axios";
import store from "store";
// import { store as reducs } from "index";

// import { store as reducstore } from "index";
// apply base url for axios
const { REACT_APP_APP_URL } = process.env;

const axiosApi = axios.create({
  headers: {
    "content-type": "application/json"
  },
  baseURL: REACT_APP_APP_URL,
  withCredentials: true
});

let requestRetries = 0;

axiosApi.interceptors.request.use(function(config) {
  const accessToken = store.get("accessToken");
  // const { user } = reducs.getState("users");
  // const { authorized } = user;
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  if (config.data instanceof FormData) {
    config.headers["content-type"] = "multipart/form-data";
  }
  return config;
});

axiosApi.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const { config } = error;
    if (error.message === "Network Error") {
      error = {
        response: { statusText: "Network error occured", status: 503 }
      };
    }
    if (
      error.response &&
      error.response.status === 401 &&
      requestRetries === 0 &&
      store.get("accessToken")
    ) {
      requestRetries = 1;
      store.remove("accessToken");
      try {
        const res = await post("/auth/token/refresh");
        if (res.access) {
          const { access } = res;
          config.headers.Authorization = `Bearer ${access}`;
          store.set("accessToken", access);
        }
        return axiosApi(config);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export async function get(url, config) {
  return axiosApi
    .get(url, {
      ...config
    })
    .then(response => {
      return response.data;
    });
}

export async function put(url, config) {
  return axiosApi
    .put(url, {
      ...config
    })
    .then(response => response.data);
}

export async function patch(url, config) {
  return axiosApi.patch(url, config).then(response => response.data);
}

export async function del(url, config) {
  return axiosApi
    .delete(url, {
      ...config
    })
    .then(response => response.data);
}

export async function post(url, config) {
  return axiosApi.post(url, config).then(response => response.data);
}
