import actions from "./actionTypes";

const initialState = {
  loadingRoles: false,
  loadingRoleDetail: false,
  roles: [],
  permissions: [],
  error: ""
};

export default function roleReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
