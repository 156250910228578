import { all } from "redux-saga/effects";
import user from "./user/sagas";
import menu from "./menu/sagas";
import settings from "./settings/sagas";
import club from "./club/sagas";
import staff from "./staff/sagas";
import sponsors from "./sponsors/sagas";
import divisions from "./divisions/sagas";
import bank from "./bank/sagas";
import player from "./player/sagas";
import role from "./role/sagas";
import season from "./season/sagas";
import carousel from "./carousel/sagas";

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    club(),
    staff(),
    sponsors(),
    divisions(),
    bank(),
    player(),
    role(),
    season(),
    carousel()
  ]);
}
