import { notification } from "antd";
// import { history } from "index";
import jwtDecode from "jwt-decode";
import store from "store";
import actions from "./actionTypes";

const decodeToken = token => {
  return jwtDecode(token);
};

export const setState = items => {
  return {
    type: actions.SET_STATE,
    payload: items
  };
};

export const activateAccount = data => {
  return {
    type: actions.ACTIVATEUSER,
    payload: data
  };
};

export const updateProfile = data => {
  return {
    type: actions.UPDATEPROFILE,
    payload: data
  };
};

export const resendActivation = data => {
  return {
    type: actions.RESENDACTIVATION,
    payload: data
  };
};

export const activationLinkSuccess = () => {
  notification.success({
    message: "Email sent",
    description: "Check the email provided for activation link!"
  });
  return {
    type: actions.SET_STATE,
    payload: {
      loading: false
    }
  };
};

export const activationLinkFail = err => {
  return {
    type: actions.SET_STATE,
    payload: {
      loading: false,
      error: err.data
    }
  };
};

export const changePass = data => {
  return {
    type: actions.SET_PASSWORD,
    payload: data
  };
};

export const activateSuccess = () => {
  notification.success({
    message: "Account Activated",
    description: "Your account has been activated!"
  });
  return {
    type: actions.SET_STATE,
    payload: {
      loading: false,
      success: true
    }
  };
};

export const activateFail = err => {
  notification.error({
    message: "Activation failed"
  });
  return {
    type: actions.SET_STATE,
    payload: {
      loading: false,
      error: err.data
    }
  };
};

export const registerUser = data => {
  return {
    type: actions.REGISTER,
    payload: data
  };
};

export const registerSuccess = () => {
  notification.success({
    message: "User registered",
    description: "You have successfully registered user!"
  });
  return {
    type: actions.SET_STATE,
    payload: {
      loading: false
    }
  };
};

export const registerFail = () => {
  notification.error({
    message: "Unexpected Error",
    description: "Registration failed"
  });
  return {
    type: actions.SET_STATE,
    payload: {
      loading: false
    }
  };
};

export const loginSuccess = response => {
  notification.success({
    message: "Logged In",
    description: "You have successfully logged in!"
  });
  store.set("accessToken", response.access);
  return {
    type: actions.LOAD_CURRENT_ACCOUNT,
    payload: {
      loading: false
    }
  };
};

export const loginFail = err => {
  notification.error({
    message: "Login Failed",
    description: err.data
  });
  return {
    type: actions.SET_STATE,
    loading: false
  };
};

export const loadCurrentSuccess = res => {
  let role = "";
  const { id, email, full_name: name, profile } = res;
  const { avatar, phone } = profile;
  const token = store.get("accessToken");
  if (token) {
    const decoded = decodeToken(token);
    const { userinf0 } = decoded;
    const { roles } = userinf0;
    role = roles;
  }
  return {
    type: actions.SET_STATE,
    payload: {
      id,
      name,
      email,
      avatar,
      phone,
      role,
      authorized: true,
      loading: false,
      detail: res
    }
  };
};

export const logout = () => {
  return {
    type: actions.LOGOUT
  };
};

export const loadCurrentFail = () => {
  return {
    type: actions.SET_STATE,
    payload: {
      loading: false
    }
  };
};
