import { notification } from "antd";
import React from "react";
import actions from "./actionTypes";

export const setState = items => {
  return {
    type: actions.SET_STATE,
    payload: items
  };
};

export const postPlayers = data => {
  return {
    type: actions.CREATE_PLAYERS,
    payload: data
  };
};

export const getPastclubPlayer = id => {
  return {
    type: actions.GET_PASTCLUB,
    payload: id
  };
};

export const postPastClub = data => {
  return {
    type: actions.CREATE_PASTCLUB,
    payload: data
  };
};

export const deletePastClub = (pk, pl) => {
  return {
    type: actions.DELETE_PASTCLUB,
    payload: {
      id: pk,
      player: pl
    }
  };
};

export const patchPastClub = pl => {
  return {
    type: actions.PATCH_PASTCLUB,
    payload: {
      id: pl.pk,
      data: pl.data
    }
  };
};

export const deletetPastclubSuccess = () => {
  notification.warning({
    message: "Data deleted successfully"
  });
  return {
    type: actions.SET_STATE,
    payload: { loadingPastClub: false }
  };
};

export const postPastclubSuccess = () => {
  notification.success({
    message: "Submitted successfully",
    description: "Submitted data will be available after approval"
  });
  return {
    type: actions.SET_STATE,
    payload: {
      loadingPastClub: false
    }
  };
};

export const getPlayersUnapproved = () => {
  return {
    type: actions.GET_PLAYERS_UNAPPROVED
  };
};

export const getPlayerLinkUnapproved = () => {
  return {
    type: actions.GET_PLAYERS_LINK_UNAPPROVED
  };
};

export const playersUnapprovedSuccess = items => {
  return {
    type: actions.SET_STATE,
    payload: {
      unapproved: items,
      loadingPlayers: false
    }
  };
};

export const getPastclubSuccess = c => {
  const currentclub = c.filter(item => item.present === true);
  return {
    type: actions.SET_STATE,
    payload: { pastClubs: c, currentClub: currentclub, loadingPastClub: false }
  };
};

export const getPastclubFail = err => {
  return {
    type: actions.SET_STATE,
    payload: {
      pastClubError: err.statusText,
      loadingPastClub: false
    }
  };
};

export const postPastclubFail = err => {
  notification.success({
    message: `${err.statusText}`,
    description: "Failed to submit data"
  });
  return {
    type: actions.SET_STATE,
    payload: {
      loadingPastClub: false
    }
  };
};

export const getPlayers = payl => {
  return {
    type: actions.GET_PLAYERS,
    payload: payl
  };
};

export const getPlayer = id => {
  return {
    type: actions.GET_PLAYER,
    payload: id
  };
};

export const deletePlayer = id => {
  return {
    type: actions.DELETE_PLAYER,
    payload: id
  };
};

export const editPlayer = data => {
  return {
    type: actions.EDIT_PLAYER,
    payload: data
  };
};

export const postPlayersSuccess = c => {
  notification.success({
    message: "Player data submitted successfully",
    description: "Submitted data will be available after approval"
  });
  return {
    type: actions.SET_STATE,
    payload: {
      playerDetail: c,
      loadingPlayers: false
    }
  };
};

export const getPlayersFail = err => {
  return {
    type: actions.SET_STATE,
    payload: {
      error: err.statusText,
      loadingPlayers: false
    }
  };
};

export const postPlayerFail = err => {
  const cf = {
    "first_name": "First Name",
    "middle_name": "Middle Name",
    "email": "Email Address",
    "last_name": "Last Name",
    "weight": "Weight",
    "nationality": "Nationality",
    "height": "Player height",
    "dob": "Date of Dirth",
    "telephone": "Telephone",
    "id_number": "Identification",
    "kin": "Next of Kin",
    "kin_telephone": "Kin Telephone",
    "country": "Country",
    "city": "City",
    "zone": "Zone",
    "parish": "Parish",
    "bio": "Biography",
    "address": "Address",
    "gender": "Gender"
  };

  const getNot = x => {
    const q = [];
    Object.keys(x).map(k => q.push(`${cf[k]}-${x[k]}`));
    return q;
  };

  if (err.data instanceof Object) {
    notification.warn({
      message: "Failed to create player",
      description: getNot(err.data).map(k => <p>{k}</p>)
    });
  } else {
    notification.warn({
      message: "Failed to create player",
      description: err.statusText
    });
  }

  return {
    type: actions.SET_STATE,
    payload: {
      error: err.statusText,
      loadingPlayerDetail: false
    }
  };
};

export const getPlayerFail = err => {
  return {
    type: actions.SET_STATE,
    payload: {
      error: err.statusText,
      loadingPlayerDetail: false
    }
  };
};

export const getPlayersSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { players: c, loadingPlayers: false }
  };
};

export const getPlayerSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { playerDetail: c, loadingPlayerDetail: false }
  };
};

export const getPlayersCurrent = id => {
  return {
    type: actions.GET_PLAYERS_CURRENT,
    payload: id
  };
};

export const getPlayersCurrentSuccess = c => {
  return {
    type: actions.SET_STATE,
    payload: { players: c, loadingPastClub: false }
  };
};

export const getPlayersCurrentFail = err => {
  return {
    type: actions.SET_STATE,
    payload: {
      error: err.statusText,
      loadingPastClub: false,
      players: []
    }
  };
};
