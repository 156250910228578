import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { connect } from "react-redux";
import DashboardBeta from "pages/dashboard/beta";
import Layout from "layouts";

const routes = [
  // Dashboards
  {
    path: "/home",
    Component: lazy(() => import("pages/dashboard/beta")),
    exact: true
  },

  // Club
  {
    path: "/club/:slug",
    Component: lazy(() => import("pages/club/bio")),
    exact: true
  },
  {
    path: "/new/club/create",
    Component: lazy(() => import("pages/club/add")),
    exact: true
  },
  {
    path: "/clubs/manage",
    Component: lazy(() => import("pages/club/manage")),
    exact: true
  },
  {
    path: "/clubs/edit/:id",
    Component: lazy(() => import("pages/club/edit")),
    exact: true
  },
  {
    path: "/clubs/edit/:id/basicinfo",
    Component: lazy(() => import("pages/club/add")),
    exact: true
  },
  {
    path: "/clubs/approvals",
    Component: lazy(() => import("pages/club/approve")),
    exact: true
  },
  // Player
  {
    path: "/player/profile/:id",
    Component: lazy(() => import("pages/player/profile")),
    exact: true
  },
  {
    path: "/player/create",
    Component: lazy(() => import("pages/player/add")),
    exact: true
  },
  {
    path: "/players/manage",
    Component: lazy(() => import("pages/player/manage")),
    exact: true
  },
  {
    path: "/players/approvals",
    Component: lazy(() => import("pages/player/approve")),
    exact: true
  },
  {
    path: "/players/link/approvals",
    Component: lazy(() => import("pages/player/linkapprove")),
    exact: true
  },

  // Users
  {
    path: "/user/add",
    Component: lazy(() => import("pages/user/adduser")),
    exact: true
  },
  {
    path: "/users/manage",
    Component: lazy(() => import("pages/user/manage")),
    exact: true
  },
  {
    path: "/users/edit/:id",
    Component: lazy(() => import("pages/user/edit")),
    exact: true
  },
  {
    path: "/users/edit/:id/basicinfo",
    Component: lazy(() => import("pages/user/adduser")),
    exact: true
  },
  {
    path: "/profile/:id/:full_name",
    Component: lazy(() => import("pages/user/profile")),
    exact: true
  },
  {
    path: "/players/edit/:id",
    Component: lazy(() => import("pages/player/edit")),
    exact: true
  },
  {
    path: "/players/approvaldetail/:id",
    Component: lazy(() => import("pages/player/approvedetail")),
    exact: true
  },
  {
    path: "/players/edit/:id/basicinfo",
    Component: lazy(() => import("pages/player/add")),
    exact: true
  },
  {
    path: "/users/approval",
    Component: lazy(() => import("pages/user/approve")),
    exact: true
  },

  {
    path: "/divisions",
    Component: lazy(() => import("pages/divisions")),
    exact: true
  },
  {
    path: "/seasons",
    Component: lazy(() => import("pages/seasons")),
    exact: true
  },

  {
    path: "/sponsors",
    Component: lazy(() => import("pages/sponsors")),
    exact: true
  },

  {
    path: "/roles/add",
    Component: lazy(() => import("pages/role")),
    exact: true
  },

  // Auth Pages
  {
    path: "/activate/:id/:tok",
    Component: lazy(() => import("pages/user/activate")),
    exact: true
  },
  {
    path: "/auth/404",
    Component: lazy(() => import("pages/auth/404")),
    exact: true
  },
  {
    path: "/carousel/manage",
    Component: lazy(() => import("pages/carousel")),
    exact: true
  },
  {
    path: "/player/print/:slug",
    Component: lazy(() => import("pages/pdfprint")),
    exact: true
  }
];

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation
});

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === "none" ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={() => {
                        return (
                          <div className={routerAnimation}>
                            <Suspense fallback={null}>
                              <DashboardBeta />
                            </Suspense>
                          </div>
                        );
                      }}
                    />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component />
                              </Suspense>
                            </div>
                          );
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </ConnectedRouter>
  );
};

export default connect(mapStateToProps)(Router);
